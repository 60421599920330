<template>
    <v-footer
        color="primary"
        style="z-index:50; position: inherit;"
    >
        <div class="d-flex align-center">
            <div v-if="show_contact_page">
                <NuxtLink to="/contact">
                    <span class="text-info">Nous contacter</span>
                </NuxtLink>
            </div>
            <v-spacer />
            <div class="px-2">
                © {{ new Date().getFullYear() }} Hydraclic
            </div>
        </div>
    </v-footer>
</template>

<script lang="ts">

import { useConfigStore } from '~/store/index'
export default {
    setup() {
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data () {
        return {
            show_contact_page : this.configStore.currentConfig.show_contact_page,
        }
    },
}
</script>
