<template>
    <div>
        <client-only>
            <div v-if="$currentUser().k">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                >
                    <template #activator="{ props }">
                        <v-btn
                            v-bind="props"
                            size="x-large"
                        >
                            <MenuAvatar :name="$currentUser().name" />
                        </v-btn>
                    </template>

                    <v-card min-width="300">
                        <v-list>
                            <v-list-item :title="$currentUser().name">
                                <v-chip
                                    size="small"
                                    :color="$getColor(`administration.utilisateurs.role.${$currentUser().role}`)"
                                >
                                    {{ $translate(`administration.utilisateurs.role.${$currentUser().role}`) }}
                                </v-chip>

                                <p class="text-caption mt-1">
                                    <v-icon size="x-small">
                                        mdi-email-outline
                                    </v-icon>
                                    {{ $currentUser().email }}
                                </p>

                                <div>
                                    <NuxtLink
                                        to="/profil"
                                        @click="menu=false"
                                    >
                                        <v-btn
                                            color="primary"
                                            class="mt-5"
                                        >
                                            Mon profil
                                        </v-btn>
                                    </NuxtLink>
                                </div>
                                <v-btn
                                    color="red"
                                    class="mt-2"
                                    @click="logout"
                                >
                                    Se déconnecter
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
            <div
                v-else
                class="mx-5"
            >
                <v-btn
                    block
                    variant="elevated"
                    color="primary"
                    @click="$router.push('/connexion')"
                >
                    Connexion
                </v-btn>
            </div>
        </client-only>
    </div>
</template>
<script lang="ts">
import {useUserStore} from '~/store'

export default {
    name : "MenuUserProfile",
    setup() {
        const access_token = useCookie<string>('access_token')
        const config = useRuntimeConfig()
        const userStore = useUserStore()

        return {
            access_token,
            config,
            userStore,
        }
    },
    data: () => ({
        menu: false,
        message: false,
    }),
    mounted () {
    },
    methods : {
        save () {
            this.menu = false
            // this.$showToaster({ text : `Le profil a été sauvegardé`, color : "success", icon : "mdi-check-circle" })
        },
        async logout ()
        {
            await this.$api.setRequest({
                url : "logout",
                method : "POST",
            });
            this.access_token = null;
            await this.userStore.clearUser()
            this.$router.push('/')
        }

    },
}
</script>
