

<template>
  <div
    v-if="maintenance"
    id="loader-overlay"
  >
    <v-row
      class="h-75 mx-2"
      justify="center"
      align="center"
    >
      <v-col class="text-center">
        <div class="profile-main-loader">
          <span class="text-h4">Maintenance en cours</span>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="h-25 mx-2"
      justify="center"
      align="center"
    >
      <v-col class="text-center">
        <slot name="logo" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {useConfigStore} from "~/store/index";

export default {
    name : "GlobalAlert",
    setup() {
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data: () => ({
        maintenance : false,
    }),
    mounted () { // LOAD ON MOUNTED OR CONFIG DOESNT HAVE VALUE
        this.maintenance = useConfigStore().maintenance
    },
}
</script>
