<template>
    <v-avatar
        color="primary"
        :size="size || 'default'"
    >
        <span class="text-h6 text-uppercase">{{ getAvatarString(name) }}</span>
    </v-avatar>
</template>

<script lang="ts">

export default {
    name : "MenuAvatar",
    props : {
        size: {
            type: String,
            required: false,
            default: null
        },
        name : {
            type: String,
            required: true
        },
    },
    methods : {
        getAvatarString (str) {
            const firstLetters = str
                .split(' ')
                .map(word => word.charAt(0))
                .join('');

            return firstLetters;
        },
    },
}
</script>
