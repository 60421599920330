<template>
    <div>
        <header>
            <v-layout>
                <MenuGlobalLoader :loading="loading">
                    <template #loader>
                        <svg viewBox="25 25 50 50">
                            <circle
                                class="loader-path"
                                cx="50"
                                cy="50"
                                r="20"
                                fill="none"
                                stroke="#636363"
                                stroke-width="2"
                            />
                        </svg>
                    </template>
                    <template #logo>
                        <img
                            class="mx-auto"
                            src="@/assets/images/logo-cleaned.png"
                            alt="Hydraclic logo"
                        >
                    </template>
                </MenuGlobalLoader>

                <MenuMaintenanceMessage>
                    <template #logo>
                        <img
                            class="mx-auto"
                            src="@/assets/images/logo-cleaned.png"
                            alt="Hydraclic logo"
                        >
                    </template>
                </MenuMaintenanceMessage>

                <client-only>
                    <MenuNavigationDrawer
                        v-if="!maintenance"
                        ref="MenuNavigationDrawer"
                    >
                        <template #menu="{ rail }">
                            <MenuNavigationSidebar
                                ref="navigationSidebar"
                                :rail="rail"
                            />
                        </template>
                    </MenuNavigationDrawer>
                    <!-- <GlobalAlert /> -->

                    <v-main
                        v-if="!maintenance && !loading"
                        id="main-content"
                        class="bg-custom-light"
                    >
                        <MenuToolbar
                            ref="Toolbar"
                            @show-navigation="$refs.MenuNavigationDrawer?.showNavigation()"
                        >
                            <template #title>
                                <!--suppress CheckImageSize -->
                                <img
                                    :src="`${$config.public.apiDomain}/public_volume/logo/dept.png`"
                                    alt="Hydraclic logo"
                                    height="50"
                                >
                            </template>
                            <template #rightMenu>
                                <MenuUserProfile />
                            </template>
                        </MenuToolbar>

                        <div
                            id="page-content"
                            class="pa-6"
                        >
                            <div>
                                <div
                                    v-if="currentPage"
                                    class="text-h4"
                                >
                                    {{ $translate(currentPage.title) }}
                                </div>
                                <MenuGlobalAlert />
                                <!-- <MenuBreadcrumbs ref="MenuBreadcrumbs" />  à aligner avec le titre à droite -->
                            </div>
                            <slot /> <!-- OUTPUT PAGE -->
                            <Toaster ref="toaster" />
                        </div>
                        <MenuFooter />
                    </v-main>
                </client-only>
            </v-layout>
        </header>
    </div>
</template>
<script lang="ts">
import {useConfigStore} from "~/store/index";

export default {
    name : "DefaultLayout",
    data: () => ({
    }),
    computed : {
        currentPage () {
            return this.$currentPath(this.$route.matched[0].path).pop()
            // return this.$refs && this.$refs.MenuBreadcrumbs && this.$refs.MenuBreadcrumbs.breadcrumbItems.slice(-1)
        },
        loading () {
            return useConfigStore()?.globalLoading
        },
        maintenance () {
            return useConfigStore()?.maintenance
        },
    },
    created () {
        // useConfigStore().setGlobalLoading(false)
    },
    mounted () {
        useConfigStore().setGlobalLoading(false)

        if (this.$currentUser().k && !this.$currentUser().territory) {
            this.$router.replace(`/erreur`)
        }
    },
    methods : {
        showTitle (path) {
            path = path.replaceAll('/', '.')
            return path
        }
    },
}
</script>
