<template>
    <div
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <!-- <v-list-item prepend-avatar="https://deci.sdis84.fr/images/DECI/horizontal-couleur.jpg" -->
        <v-list-item nav>
            <div class="w-100 text-center">
                <img
                    src="@/assets/images/logo-cleaned.png"
                    alt="Logo Hydraclic"
                    :width="!rail || hover ? 150 : 40"
                >
            </div>
        </v-list-item>

        <v-divider />

        <v-list
            density="compact"
            nav
        >
            <NuxtLink to="/">
                <v-list-item
                    prepend-icon="mdi mdi-application-outline"
                    value="home"
                >
                    <v-list-item-title>Accueil</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <!-- DASHBOARD -->
            <div v-if="$permissionCheck('stats_show') && $currentUser().territory">
                <NuxtLink to="/tableau-de-bord">
                    <v-list-item
                        prepend-icon="mdi mdi-view-dashboard"
                        value="dashboard"
                    >
                        <v-list-item-title>Tableau de bord</v-list-item-title>
                    </v-list-item>
                </NuxtLink>
            </div>

            <NuxtLink to="/ressources">
                <v-list-item
                    prepend-icon="mdi mdi-file-document-multiple-outline"
                    value="documents"
                >
                    <v-list-item-title>Ressources</v-list-item-title>
                </v-list-item>
            </NuxtLink>

            <!-- GESTION -->
            <v-list-subheader
                v-if="$hasOnePermissionCheck(['pei_page','pei_display_all','importPei_page','changeLog_page', 'unavailability_page', 'simultaneousControl_page', 'tour_page', 'sentEmail_page'])"
                class="text-uppercase mt-5"
            >
                Point d'eau incendie
            </v-list-subheader>


            <NuxtLink
                v-if="$permissionCheck('pei_page') || $permissionCheck('pei_display_all')"
                :to="`/gestion/pei${show_map_pei_page ? '?onglet=liste' : ''}`"
            >
                <v-list-item
                    prepend-icon="mdi mdi-format-list-bulleted"
                    title="Gestion des PEI"
                    value="liste"
                />
            </NuxtLink>
            <NuxtLink
                v-if="($permissionCheck('pei_page') || $permissionCheck('pei_display_all')) && show_map_pei_page"
                to="/gestion/pei?onglet=carte"
            >
                <v-list-item
                    prepend-icon="mdi mdi-map"
                    title="Carte des PEI"
                    value="carte"
                />
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('importPei_page')"
                to="/gestion/import"
            >
                <v-list-item
                    prepend-icon="mdi mdi-file-import-outline"
                    title="Import des PEI"
                    value="import"
                />
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('changeLog_page')"
                to="/gestion/historique"
            >
                <v-list-item
                    prepend-icon="mdi mdi-history"
                    title="Historique"
                    value="historique"
                />
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('unavailability_page')"
                to="/gestion/indisponibilites-programmees"
            >
                <v-list-item
                    prepend-icon="mdi mdi-clipboard-text-clock-outline"
                    value="indispo"
                >
                    <v-list-item-title>Indisponibilités</v-list-item-title>
                </v-list-item>
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('simultaneousControl_page')"
                to="/gestion/controles-simultanes"
            >
                <v-list-item
                    prepend-icon="mdi mdi-circle-double"
                    value="simultane"
                >
                    <v-list-item-title>Contrôles simultanés</v-list-item-title>
                </v-list-item>
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('tour_page')"
                to="/gestion/tournees"
            >
                <v-list-item
                    prepend-icon="mdi mdi-hexagon-outline"
                    value="tournee"
                >
                    <v-list-item-title>Tournées</v-list-item-title>
                </v-list-item>
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('sentEmail_page')"
                to="/gestion/envoi-de-mail"
            >
                <v-list-item
                    prepend-icon="mdi-email-arrow-right-outline"
                    value="envoimail"
                >
                    <v-list-item-title>Envoi de mail</v-list-item-title>
                </v-list-item>
            </NuxtLink>
            <!-- </v-list-group> -->

            <!-- ADMINISTRATION -->
            <v-list-subheader
                v-if="$permissionCheck('stakeholder_page')
                    || $permissionCheck('user_page')
                    || $permissionCheck('territory_page')
                    || $hasOnePermissionCheck(['config_show','notificationConfig_show','emailTemplate_page'])"
                class="text-uppercase mt-5"
            >
                Administration
            </v-list-subheader>
            <NuxtLink
                v-if="$permissionCheck('stakeholder_page')"
                to="/administration/acteurs"
            >
                <v-list-item
                    prepend-icon="mdi mdi-badge-account"
                    value="acteurs"
                >
                    <v-list-item-title>Acteurs</v-list-item-title>
                </v-list-item>
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('territory_page')"
                to="/administration/territoires"
            >
                <v-list-item
                    prepend-icon="mdi mdi-city-switch"
                    value="territoires"
                >
                    <v-list-item-title>Territoires</v-list-item-title>
                </v-list-item>
            </NuxtLink>
            <NuxtLink
                v-if="$permissionCheck('user_page')"
                to="/administration/utilisateurs"
            >
                <v-list-item
                    prepend-icon="mdi mdi-account-multiple"
                    value="utilisateurs"
                >
                    <v-list-item-title>Utilisateurs</v-list-item-title>
                </v-list-item>
            </NuxtLink>
            <NuxtLink
                v-if="$hasOnePermissionCheck(['config_show','notificationConfig_show','emailTemplate_page'])"
                to="/administration/configuration"
            >
                <v-list-item
                    prepend-icon="mdi mdi-cog"
                    value="configuration"
                >
                    <v-list-item-title>Paramètres</v-list-item-title>
                </v-list-item>
            </NuxtLink>
        </v-list>
    </div>
</template>

<script lang="ts">
import {useConfigStore} from '~/store'

export default {
    name : "NavigationSidebar",
    props : {'rail':{
        type: Boolean,
        default: false
    }},
    setup() {
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data () {
        return {
            hover : false,
            show_map_pei_page : this.configStore.currentConfig.show_map_pei_page,
        }
    },
    computed : {
        isLoggedIn () {
            return this.$currentUser().k
        }
    },
    methods : {
        redirectToList () {
            if (!this.$route.path.includes('/pei')) {
                this.$router.push('/gestion/pei')
            }
        }
    },
}
</script>
